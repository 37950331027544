import { configureStore, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { createApiBase } from "config/api.config";
import appSlice from "slices/app.slice";

const rootPersistConfig = {
  key: "pudel",
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key",
    }),
  ],
  storage: storage,
  blacklist: [createApiBase.reducerPath, "app"],
};

export const doLogout = createAction("logout");

const rootReducer = combineReducers({
  app: appSlice,
  [createApiBase.reducerPath]: createApiBase.reducer,
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer as any);

const middleware = [createApiBase.middleware];

const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(...middleware),
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
