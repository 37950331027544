import Box, { BoxProps } from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import { vwMobile } from "theme/muiSizes";

export const PagesContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: "40px 24px 70px",
  minHeight: "calc(100vh - 70px)",
  boxSizing: "border-box",
  position: "relative",
  overflow: "hidden",
  backgroundImage: `linear-gradient(to right, ${
    theme.palette.background.default
  } 0%, ${theme.palette.background.default} 70%, ${alpha(
    theme.palette.common.white,
    0.7
  )} 100%) `,
  [theme.breakpoints.down(440)]: {
    padding: `${vwMobile(40)} ${vwMobile(20)} ${vwMobile(70)}`,
    minHeight: `calc(100vh - ${vwMobile(70)})`,
  },
}));
